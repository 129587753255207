// Overrides
// https://github.com/Rocketseat/gatsby-themes/blob/master/%40rocketseat/gatsby-theme-docs/src/components/Logo.js
import styled from '@emotion/styled';

import logo from '../assets/logo.png';

export default styled.div`
  width: 180px;
  height: 70px;
  background-size: contain;
  background: url(${logo}) center no-repeat;
`;